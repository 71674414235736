<template>
<div>
    <van-nav-bar :left-arrow="true" left-text="返回" title="订单详情" fixed :placeholder="true" @click-left="goBack" />
    <div class="home-wrap">
        <div class="main-card-wrap">
            <div class="flex-between">
                <div class="left flex-column flex-around">
                    <div class="flex-row flex-middle">
                        <div class="title">订单ID：{{order.id}}</div>
                        <div style="margin-left: 4px; align-items: center;">
                            <van-icon  name="notes-o" @click="copy(order)"/>
                        </div> 
                        
                        
                        
                    </div>
                    <van-divider :style="{ padding: '0 0px',margin:'0 0px' }" />
                    <div class="title">片名：{{order.movieName}}</div>
                    <van-divider :style="{ padding: '0 0px',margin:'0 0px' }" />
                    <div class="title">价格：{{order.channelUnitPrice}} * {{order.quantity}} <span style="color:red;">（中标价：{{order.orderUnitPrice}} * {{order.quantity}}）</span></div>
                    <van-divider :style="{ padding: '0 0px',margin:'0 0px' }" />
                    <div class="title">城市：{{order.cityName}}</div>
                    <van-divider :style="{ padding: '0 0px',margin:'0 0px' }" />
                </div>
                <div class="right flex-top flex-center">
                    <van-image width="60px" height="80px" fit="fit" :src="order.logo+'_200x200'" />
                </div>
            </div>
            <div class="left flex-column flex-around">
                <div class="title">影院：{{order.cinemaName}}</div>
                <van-divider :style="{ padding: '0 0px',margin:'0 0px' }" />
                <div class="title">地址：{{order.cinemaAddress}}</div>
                <van-divider :style="{ padding: '0 0px',margin:'0 0px' }" />
                <div class="title" :style="{marginTop:'20px'}">场次：<span style="font-weight: bold;font-size: 16px">{{order.playTimeText}}</span></div>
                <div class="title">版本：{{order.edition}}</div>
                <van-divider :style="{ padding: '0 0px',margin:'0 0px' }" />
                <div class="title">影厅：{{order.hallName}}</div>
                <van-divider :style="{ padding: '0 0px',margin:'0 0px' }" />
                <div class="title">座位：{{order.seats}}</div>
                <van-divider :style="{ padding: '0 0px',margin:'0 0px' }" />
                <div class="title">备注：<van-tag round size="medium" :type="order.supportExchangeSeat === 'Y'?'primary':'danger'">{{order.supportExchangeSeat === 'Y'?'支持换座':'不支持换座'}}</van-tag>
                </div>
            </div>
        </div>

        <div class="main-card-wrap">
            <div class="left flex-column flex-around">
                <van-button v-if="order.channel !='taobao'" size="small" type="info" round block @click="unlockSeat(order.id)">释放座位</van-button>
                <van-button v-if="order.channel =='taobao'" size="small" type="info" round block @click="getSeatPic(order.id)">查看座位图</van-button>
            </div>
        </div>
        <van-dialog v-model="showDialog" title="座位原图" confirmButtonText="关闭" @close="showDialog = false">
            <img :src="imageUrl" alt="预览图" style="width: 100%;" />
        </van-dialog>

        <div class="main-card-wrap">
            <van-form @submit="uploadTicketCode">
                <van-cell-group>
                    <van-row style="border: 1px solid #ccc;" type="flex" v-if="order.quantity>=1">
                        <van-row span="24">
                            <van-field v-model="order.xlh1" name="取票码1" label="取票码1" placeholder="取票码1" />
                        </van-row>
                        <van-row span="24">
                            <van-field v-model="order.yzm1" name="验证码1" label="验证码1" placeholder="验证码1" />
                        </van-row>
                    </van-row>

                    <van-row style="border: 1px solid #ccc;" type="flex" v-if="order.quantity>=2">
                        <van-row span="24">
                            <van-field v-model="order.xlh2" name="取票码2" label="取票码2" placeholder="取票码2" />
                        </van-row>
                        <van-row span="24">
                            <van-field v-model="order.yzm2" name="验证码2" label="验证码2" placeholder="验证码2" />
                        </van-row>
                    </van-row>

                    <van-row type="flex" v-if="order.quantity>=3">
                        <van-row span="24">
                            <van-field v-model="order.xlh3" name="取票码3" label="取票码3" placeholder="取票码" />
                        </van-row>
                        <van-row span="24">
                            <van-field v-model="order.yzm3" name="验证码3" label="验证码3" placeholder="验证码" />
                        </van-row>
                    </van-row>

                    <van-row type="flex" v-if="order.quantity>=4">
                        <van-row span="24">
                            <van-field v-model="order.xlh4" name="取票码4" label="取票码4" placeholder="取票码" />
                        </van-row>
                        <van-row span="24">
                            <van-field v-model="order.yzm4" name="验证码4" label="验证码4" placeholder="验证码" />
                        </van-row>
                    </van-row>

                    <van-row type="flex" v-if="order.quantity>=5">
                        <van-row span="24">
                            <van-field v-model="order.xlh5" name="取票码5" label="取票码5" placeholder="取票码" />
                        </van-row>
                        <van-row span="24">
                            <van-field v-model="order.yzm5" name="验证码5" label="验证码5" placeholder="验证码" />
                        </van-row>
                    </van-row>
                    <van-row type="flex" v-if="order.quantity>=6">
                        <van-row span="24">
                            <van-field v-model="order.xlh6" name="取票码6" label="取票码6" placeholder="取票码" />
                        </van-row>
                        <van-row span="24">
                            <van-field v-model="order.yzm6" name="验证码6" label="验证码6" placeholder="验证码" />
                        </van-row>
                    </van-row>
                    <!-- <van-field
                v-model="order.remark"
                name="订单备注"
                label="订单备注"
                placeholder="请输入订单备注信息"
            /> -->
                </van-cell-group>
                <van-field name="uploader" label="图片取票码">
                    <template #input>
                        <van-uploader v-model="files" multiple :max-count="order.quantity" :after-read="afterRead" result-type="text" :before-delete="beforeDelete" :disabled="!order.canUploadTicketCode" />
                    </template>
                </van-field>
                <div style="margin: 16px;" v-if="order.canUploadTicketCode">
                    <van-button round block type="info" size="small" native-type="submit">保存取票码</van-button>
                </div>
            </van-form>
        </div>

    </div>
</div>
</template>

<script>
import Api from '@/api-service'
export default {
    name: "Wait-Ticket",
    data() {
        return {
            order: {},
            orderId: '',
            files: [],
            filePaths: [],
            imageUrl: '',
            showDialog:false
        }
    },
    created() {
        this.orderId = this.$route.query.id;
        console.log(this.orderId);
        if (this.orderId) {
            this.fetchData();
        }
    },
    methods: {
        fetchData() {
            Api.getOrderDetail({
                id: this.orderId
            }).then(res => {
                this.order = res;
                this.files = res.ticketImgList;
                this.filePaths = res.ticketImgList;
            })
        },
        goBack() {
            this.$router.go(-1);
        },
        afterRead(file) {
            this.uploadImage(file);
        },
        beforeDelete(file) {
            // var fileName = file.name;
            // this.filePaths = this.filePaths.filter(item => item.name !== fileName);
            // this.files = this.filePaths;
            var newUploadList = []
            var i = 0
            var size = this.files.length
            var self = this
            this.files.forEach(function(v, index) {
                if (file.url !== v.url) {
                    newUploadList.push(v)
                } else {
                    i = 1
                }

                var xlh_begin = 'xlh' + (index + 1)
                var yzm_begin = 'yzm' + (index + 1)

                var tips_begin = 'tips' + (index + 1)
                var seat_begin = 'seat' + (index + 1)

                if (index + 1 === size) {
                    // 最后一个，置空
                    self.order[xlh_begin] = ''
                    self.order[yzm_begin] = ''
                    self.order[tips_begin] = ''
                    self.order[seat_begin] = 0
                } else {
                    var xlh_next = 'xlh' + (index + 1 + i)
                    var yzm1_next = 'yzm' + (index + 1 + i)
                    var tips_next = 'tips' + (index + 1 + i)
                    var seat_next = 'seat' + (index + 1 + i)

                    self.order[xlh_begin] = self.order[xlh_next]
                    self.order[yzm_begin] = self.order[yzm1_next]
                    self.order[tips_begin] = self.order[tips_next]
                    self.order[seat_begin] = self.order[seat_next]
                }
            })
            this.files = newUploadList
            this.showUploadList()
        },
        uploadImage(file) {
            var toast = this.$toast.loading({
                duration: 10000, // 持续展示 toast
                forbidClick: false,
                overlay: true,
                message: '上传中，请耐心等待',
            });
            
            Api.uploadImage({
                file: file.file,
                type:'4',
                order_id: this.orderId
            }).then(res => {
                toast.clear();
                console.log(res);
                this.files = [];
                this.filePaths.push(res);
                this.files = Object.assign([], this.filePaths);

                if (res.code.length > 0) {
                    const arr = res.code.split('|')
                    if (arr.length === 1) {
                        var propertyName = 'xlh' + this.filePaths.length
                        this.order[propertyName] = arr[0]
                    } else {
                        var xlh = 'xlh' + this.filePaths.length
                        var yzm = 'yzm' + this.filePaths.length
                        this.order[xlh] = arr[0]
                        this.order[yzm] = arr[1]
                    }
                }

                var propertySeat = 'seat' + this.filePaths.length
                this.order[propertySeat] = res.seat_cnt
                if (res.match_type !== 1) {
                    // 比对有误
                    var propertyTip = 'tips' + this.filePaths.length
                    this.order[propertyTip] = res.data.tips
                    if (res.match_type === 2) {
                        // 比对失败，弹对话框
                        this.$message.error('核对失败：' + res.tips)
                    }
                    console.log(this.order)
                }
                this.showUploadList()
                // this.$message.success('上传成功')
                this.$nextTick(() => {})
            }).then(err => {
                toast.clear();
            })
        },
        uploadTicketCode() {
            // this.order.ticketImg = this.filePaths.map(item => item.url).join(',');
            console.log(this.order);
            Api.uploadOrderTicketCode(this.order).then(res => {
                this.$toast.success("上传取票码成功");
            })
      },
        unlockSeat(id) {
            Api.unlockOrder({
                id: id
            }).then(res => {
                this.$toast.success("已发送释放座位请求");
            })
        },
        getSeatPic(id) {
            Api.getSeatPic({
                order_id: id
            }).then(res => {
              this.showDialog = true;
                this.imageUrl = res.url;
            })
        },
        showUploadList() {
            var tm = []
            this.files.forEach(function(v, index) {
                console.warn(v.url, index)
                tm.push(v.url)
            })
            if (tm.length > 0) {
                this.order.ticketImg = tm.join(',')
            } else {
                this.order.ticketImg = ''
            }
        },
        async copy(order) {
            console.log(order)
            try {
                const content = `
城市:${order.cityName},
影院名字:${order.cinemaName},
影院地址:${order.cinemaAddress},
影片: ${order.movieName},
场次:${order.playTimeText},
影厅:${order.hallName},
座位:${order.seats},
市场价:${order.channelUnitPrice},
`.trim();
// 使用 .trim() 来移除字符串前后的空格
                // 调用浏览器 API 复制内容到剪贴板
                await navigator.clipboard.writeText(content);
                // 复制成功后的提示
                this.$toast.success('内容已复制到剪贴板');
            } catch (err) {
                // 复制失败的错误处理
                console.error('复制失败:', err);
                this.$toast.fail('复制失败');
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.home-wrap {
    height: 100%;
    background-color: #f3f3f3;

    .main-card-wrap {
        margin: 10px 10px;
        padding: 16px;
        border-radius: 6px;
        background-color: white;
        -moz-box-shadow: 2px 2px 3px #ccc;
        -webkit-box-shadow: 2px 2px 3px #ccc;
        box-shadow: 2px 2px 3px #ccc;

        .left {
            .title {
                font-size: 14px;
                margin-top: 6px;
            }

            .subtitle {
                font-size: 14px;
                margin-top: 6px;
            }

            .priceTitle {
                font-size: 16px;
                color: #c75450;

            }

            .otherTitle {
                font-size: 14px;
                margin-top: 6px;
                color: #999;
            }

            .btn {
                height: 34px;
                width: 120px;
                color: white;
                margin-top: 6px;
                border-radius: 4px;
                background-color: #8565bd;
            }
        }

        .right {
            margin-right: 8px
        }
    }
}
</style>
